import * as React from 'react';
import Helmet from 'react-helmet';

const TermsPage: React.FC = () => {
  const months = ['January', 'February', 'March','April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  let current_datetime = new Date();
  let formatted_date = current_datetime.getDate() + ' ' + months[current_datetime.getMonth()] + ' ' + current_datetime.getFullYear();

  return (
    <>
      <Helmet title="Terms of use" />
      <section className="section--terms">
        <div className="inner inner-padding">
          <h2 className="text-left heading-2-small">Terms of Use</h2>
          <p className="text-left regular">BlueValue is a database of ecosystem services research and resources. Our vision is that BlueValue will help researchers, policy makers, natural resource managers, and the general public work together to ensure the long-term sustainable use and conservation of watershed, coastal, and marine resources.&nbsp;</p>
          <p className="text-left regular">BlueValue is a project of the Socio-Economics Group at <a href="https://www.harteresearchinstitute.org/" title="harteresearchinstitute.org">Harte Research Institute for Gulf of Mexico Studies</a>&nbsp;at&nbsp;<a href="https://www.tamucc.edu/" title="tamucc.edu">Texas A&amp;M University-Corpus Christi</a>.&nbsp;</p>
          <div className="p-heading--strong font-pn-bold">Copyright</div>
          <p className="text-left regular">All materials used on this website are the sole property of BlueValue.org unless otherwise stated. Any unauthorized use of these materials is prohibited. Bluevalue’s copyright statement covers all published materials, and provides procedures for obtaining copyright permission that should be used consistently throughout BlueValue.</p>
          <p className="text-left regular">To cite BlueValue, use: Harte Research Institute for Gulf of Mexico Studies. 2020. BlueValue: Resource Database for Ecosystem Services. Texas A&amp;M University-Corpus Christi. World Wide Web electronic publication.&nbsp;<a href="https://www.bluevalue.org/" title="bluevalue.org">https://www.bluevalue.org</a>, June&nbsp;2020.</p>
          <div className="p-heading--strong font-pn-bold">Citation</div>
          <p className="text-left regular">Harte Research Institute for Gulf of Mexico Studies. 2020. BlueValue: Resource Database for Ecosystem Services. Texas A&amp;M University-Corpus Christi. World Wide Web electronic publication. https://www.bluevalue.org, June 2020.  </p>
          <div className="p-heading--strong font-pn-bold">Credit given to BlueValue</div>
          <p className="text-left regular">Harte Research Institute for Gulf of Mexico Studies. 2020. BlueValue: Resource Database for Ecosystem Services. Texas A&amp;M University-Corpus Christi. World Wide Web electronic publication.&nbsp;<a href="https://www.bluevalue.org/" title="bluevalue.org">https://www.bluevalue.org</a>, {formatted_date}.</p>
          <div className="p-heading--strong font-pn-bold">Credit given to third parties</div>
          <p className="text-left regular">Some published materials, such as images, may note third party copyrights. All copyrights should be observed and credited accordingly.</p>
          <div className="p-heading--strong font-pn-bold">Obtaining permissions</div>
          <p className="text-left regular">If you are seeking permission to use BlueValue material, logos, screen shots, pictures, forms, documents, or other intellectual property please&nbsp;<a href="/contact/">contact us</a>.</p>
        </div>
      </section>
    </>
  );
};

export default TermsPage;
